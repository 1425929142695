import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { GA_TRACKING_ID, pageView } from '../lib/gtag';
import theme from '../themes/primary';
import '../styles/globals.css';
import '../styles/colors.css';
import '../styles/export.css';

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout = Component.getLayout || ((page) => page);

  return getLayout(
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <Script
          id="hotjar-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${hotjarId},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
          }}
        />
      )}

      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </GoogleOAuthProvider>
      <ToastContainer />
    </>,
  );
}

MyApp.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.element, PropTypes.func,
  ]),
  pageProps: PropTypes.object, // eslint-disable-line
};

MyApp.defaultProps = {
  Component: () => {},
  pageProps: null,
};
