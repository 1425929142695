import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    blue: {
      mid: '#2F4870',
      main: '#3E4AD0',
      dark: '#213350',
      light: '#84A5DD',
    },
    gray: {
      light: '#EEF1F4',
      dark: '#AAB8CE',
    },
    yellow: {
      main: '#FFD441',
      dark: '#FFCA41',
    },
    red: {
      main: '#FF5772',
    },
    cyan: {
      main: '#2FC5C3',
    },
    purple: {
      main: '#838DFB',
    },
    white: {
      main: '#FFFFFF',
    },
    background: {
      main: '#E2E2E2',
    },
  },
  typography: {
    fontFamily: {
      inter: ['Inter', 'sans-serif'],
    },
  },
});

export default theme;
